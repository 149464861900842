import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import AvatarImage from '@shared/ui/display/AvatarImage';
import Tooltip from '@shared/ui/tooltips/Tooltip';
import Badge from '@shared/ui/badges/Badge';
import { ReactComponent as TraderIcon } from '@icons/wolfkit-light/crown-light.svg';
import { ReactComponent as ErrorIconComponent } from '@icons/wolfkit-light/alert-circle-light.svg';
import { formatAmount } from '@src/shared/lib/Helpers';
import { Body, BodyMedium, BodyMediumSemiBold } from '@components/styled/Typography';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import Icon from '@shared/ui/icons/Icon';
import withTextEllipsis from '@shared/ui/tooltips/withTextEllipsis';
const IncomeBadge = withPercentageIncomeBadge(BodyMedium);
const ProfitReviewContainer = styled.div(() => ({
    borderRadius: '6px',
    border: '1px solid #EFF1F3',
    backgroundColor: '#FBFBFB',
    padding: '12px 12px 16px 12px',
    width: '100%',
    boxSizing: 'border-box',
}));
const AvatarImageContainer = styled.div(() => ({
    width: 32,
    minWidth: 32,
    maxWidth: 32,
    height: 32,
}));
const UserInfo = styled.div((props) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    paddingBottom: '8px',
    borderBottom: `1px solid ${props.theme.customColors.button.pill.border}`,
    marginBottom: '8px',
    fontWeight: 500,
    cursor: 'default',
    userSelect: 'none',
    color: props.theme.palette.text.secondary,
}));
const ProfitInfo = styled.div(() => ({
    fontSize: '12px',
    lineHeight: '20px',
    marginBottom: '6px',
    cursor: 'default',
    userSelect: 'none',
}));
const AccountAssets = styled(ProfitInfo)(() => ({
    marginBottom: '0',
}));
const AssetsValue = styled.div(() => ({
    display: 'flex',
    alignItems: 'baseline',
    gap: '0 10px',
    lineHeight: '20px',
}));
const ProfitValue = styled.div(() => ({
    display: 'flex',
    alignItems: 'baseline',
    gap: '0 10px',
}));
const ErrorIcon = styled(ErrorIconComponent)(props => ({
    path: {
        fill: props.theme.palette.text.secondary,
    },
}));
const BodyWithEllipsis = styled(withTextEllipsis(Body))(() => ({}));
const UserText = styled.span(() => ({}));
const ReviewDetailsContainer = styled.div(props => ({
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: props.theme.spacing_sizes.l,
    justifyContent: 'space-between',
}));
const MiniTraderBadge = ({ single = undefined }) => {
    const { t } = useTranslation();
    return (_jsx(Badge, { variant: 'tinted', color: 'warning', startAdornment: (_jsx(Icon, { size: 14, IconComponent: TraderIcon })), children: single ? t('profile.type.trader') : '' }));
};
const ProfitReview = ({ info = undefined, single = undefined }) => {
    var _a;
    const { t } = useTranslation();
    return (_jsxs(ProfitReviewContainer, { children: [_jsxs(UserInfo, { children: [_jsx(AvatarImageContainer, { children: _jsx(AvatarImage, { publicName: info === null || info === void 0 ? void 0 : info.name, size: 'thumbnail' }) }), _jsx(BodyWithEllipsis, { tooltipText: `${(info === null || info === void 0 ? void 0 : info.name) || ''}`, children: `${(info === null || info === void 0 ? void 0 : info.name) || ''}` }), (info === null || info === void 0 ? void 0 : info.user) === 'trader' ?
                        _jsx(MiniTraderBadge, { single: single }) :
                        _jsx(UserText, { children: t('portfolio_overview.user', { ns: 'common' }) })] }), _jsxs(ReviewDetailsContainer, { single: single, children: [_jsxs(ProfitInfo, { children: [Trans({
                                ns: 'common',
                                i18nKey: 'portfolio_overview.sections.profit_review.profit_period',
                                values: { period: '27 days' },
                            }), _jsxs(ProfitValue, { children: [_jsx(IncomeBadge, { badgeVariant: 'tinted', percentageIncome: ((_a = info === null || info === void 0 ? void 0 : info.profit) === null || _a === void 0 ? void 0 : _a.percentage) || 0 }), _jsx(Tooltip, { title: `${formatAmount(info === null || info === void 0 ? void 0 : info.profit.value)} ${(info === null || info === void 0 ? void 0 : info.profit.currency) || ''}`, placement: 'top', children: _jsx(Icon, { IconComponent: ErrorIcon, size: 14 }) })] })] }), _jsxs(AccountAssets, { children: [t('portfolio_overview.sections.profit_review.assets', { ns: 'common' }), _jsx(AssetsValue, { children: _jsxs(Badge, { variant: 'tinted', color: 'primary', children: [_jsx(BodyMediumSemiBold
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        , { 
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            component: 'span', children: (info === null || info === void 0 ? void 0 : info.assets.value) ? formatAmount(info.assets.value) : '' }), _jsx(BodyMedium
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        , { 
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            component: 'span', children: info === null || info === void 0 ? void 0 : info.assets.currency })] }) })] })] })] }));
};
export default ProfitReview;
